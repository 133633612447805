import React, { useState, useEffect, useRef } from "react";
import API from "../utils/request";
import { COMPAIGN_DELETE_URL, DOWNLOAD_PDF, UPDATE_PRIORITY, UPDATE_STATUS } from "../api/urls";
import { Link } from "react-router-dom";
import iconEdit from "../assets/images/iconEdit.svg";
import { FeedESTDateToString } from "../functions/format/date";
import { BiPlusCircle, BiSortDown, BiSortUp } from 'react-icons/bi'
import { bgCheck, dataRequestTypeList, progressStatus } from '../containers/FixedData/fixedData';
import iconWarning from "../assets/images/warning-icon.jpg"
import MessageModal from '../containers/modal/messageModal';
import MessageModalMain from "../containers/modal/messageModalMain";
import downloadjs from 'js-file-download';
import JireModal from "../containers/modal/jiraModal";
import { Spin, Tooltip } from "antd";
import EditSvg from "../assets/images/editSvg";
import { FaRegStar, FaStar } from "react-icons/fa";
import CustomPagination from "../containers/CustomPagination";

const WebFormList = (props) => {
    const {
        setLoading,
        limitList,
        setLimitList,
        dataForm,
        setDataForm,
        fetchUserFormList,
        fetchUserFormListNew,
        pagination,
        setPagination,
        setSortField,
        setSort,
        sort,
        sortField,
        totalCount,
        setIsLoading,
        loading,
    } = props;
    const messageModalRef = useRef('rememberMe');
    const [messageModal, setMessageModal] = useState(false)
    const [indexDelete, setIndexDelete] = useState(null)
    const [messageFetch, setMessageFetch] = useState("")
    const [item, setItem] = useState("")
    const [id, setId] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [jiraShow, setJiraShow] = useState("")
    const [campaignId, setCampaignId] = useState("")
    const [jiraData, setJiraData] = useState("")
    const [sortFields, setSortFields] = useState([
        {
            name: "Date",
            sort: 1,
            sortField: "_id",

        },

        {
            name: "Name",
            sort: 1,
            sortField: "firstName",

        },
        {
            name: "Email",
            sort: 1,
            sortField: "email",

        },
        {
            name: "Title",
            sort: 1,
            sortField: "title",

        },
        {
            name: "Request Type",
            sort: 1,
            sortField: "requestType",

        },
        {
            name: "JIRA#",
        },
        {
            name: "Progress",
            sort: 1,
            sortField: "status"

        },
    ]);

    const updateStatusCompaing = (event, item) => {
        API.post(UPDATE_STATUS, {
            campaignId: item._id,
            status: event.target.value,
        })
            .then((res) => {
                if (res.status === 1) {
                    item.status = event.target.value
                    setDataForm([...dataForm])
                    fetchUserFormList();
                    setIsLoading(false)
                    setLoading(false)
                } else {
                    setLoading(false)
                setIsLoading(false)
                    alert(res.message);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    // Sort by ascending & descending for stakeholder
    const handleSort = (item, index) => {

        setSort(item.sort)
        if (item.sort === 1) {
            sortFields[index].sort = -1;
        } else {
            sortFields[index].sort = 1
        }
        setSortField(item.sortField);
        setSortFields([...sortFields])
    }

    // change icon when sort ascending && descending
    const changeIcon = (type) => {
        switch (type) {
            case 1:
                return <BiSortDown />;
            case -1:
                return <BiSortUp />;
            default:
                return null;
        }
    };
    // update-priority
    
    const updatePriority = (id, isPriority, item) => {
        API.post(UPDATE_PRIORITY, {
            campaignId: id,
            isPriority: isPriority === 1 ? 0 : 1
        }).then((res) => {
            if (res.status === 1) {
                item.isPriority = isPriority === 1 ? 0 : 1
                setDataForm([...dataForm])
                setLoading(false)
                setIsLoading(false)
                fetchUserFormList(true);
            }
            return false;
        }).catch((err) => {
            setLoading(false)
            setIsLoading(false)
            console.log(err)
        })
    }

    const deleteUser = (e) => {
        API.post(COMPAIGN_DELETE_URL, {
            "campaignId": id
        }).then((res) => {
            if (res.status === 1) {
                messageModalRef.current.showSuccess("The intake webform has been successfully deleted.");
                setMessageModal(false)
                fetchUserFormList()
                return false;
            } else {
                messageModalRef.current.showWarning(res.message);
            }
        }).catch((err) => {
            messageModalRef.current.showWarning(err.message);
            setLoading(false)
        })
    }
    const deleteCampaginMessage = (item, index) => {
        setIndexDelete(index)
        setMessageFetch("Open")
        setItem(item)
        setMessageModal(true)
        setId(item._id)

    };

    const downloadPDF = (item) => {
        API.post(DOWNLOAD_PDF, {
            "campaignId": item._id,
        }, { responseType: 'blob' }).then((res) => {
            downloadjs(res, `CampaignIntakeForm_${item.firstName + ' ' + item.lastName}_${item.title}.pdf`);
        }).catch((err) => {
            console.log(err);
            setLoading(false)
        })
    }

    function split(item) {
        var avg, splitted, part1, part2;
        splitted = item.split("@");
        part1 = splitted[0];
        avg = part1.length / 2;
        part1 = part1.substring(0, (part1.length - avg));
        part2 = splitted[1];
        return part1 + "...@" + part2;
    }


    useEffect(() => {
        fetchUserFormList()
        setLoading(true)
    }, [sortField, sort, limitList]);

    const openJiraModal = (e) => {
        e.preventDefault()
        setJiraShow("Open")
        setShowModal(true)
    }

    return (
        <div className="col-md-12">
            <div className="table-responsive" style={{ minHeight: '200px' }}>
            {/* <Spin spinning={loading}> */}
                <table className="table">
                    <thead className="thead-primary">
                        <tr>
                            <th>No.</th>
                            {
                                sortFields.map((field, i) => {
                                    if (field.name === "JIRA#") {
                                        return (
                                            <th key={i}>
                                                {field.name}
                                            </th>
                                        );
                                    }
                                    return (
                                        <th key={i} onClick={() => handleSort(field, i)}>
                                            {field.name} {changeIcon(field.sort)}
                                        </th>
                                    );
                                })
                            }
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataForm.map((value, index) => (
                            // value.isPriority !== 1 ? 
                            <tr>
                                <td className="td_radiusLeft" style={{paddingLeft:"20px", width:"10px"}}>
                                    {index + 1}
                                </td>
                                <td>
                                    <p>{FeedESTDateToString(value.submitDate)}</p>
                                </td>
                                <td >{`${value.firstName} ${value.lastName}`}</td>
                                <td><Tooltip placement="top" title={value.email}>{split(value.email)}</Tooltip></td>
                                <td>
                                    <Tooltip
                                        placement="top"
                                        title={
                                            value.requestType === "technical-ticket"
                                                ? value.summaryTitle
                                                : value.title
                                        }
                                    >
                                        {value.requestType === "technical-ticket"
                                            ? value.summaryTitle && value.summaryTitle.length > 30
                                                ? value.summaryTitle.slice(0, 30) + '...'
                                                : value.summaryTitle
                                            : value.title && value.title.length > 30
                                                ? value.title.slice(0, 30) + '...'
                                                : value.title}
                                    </Tooltip>
                                </td>
                                <td >{`${dataRequestTypeList(value?.requestType)}`}</td>
                                <td>
                                    {
                                        value.jiraTask && value.jiraTask.key?
                                            <Tooltip
                                                title={
                                                    <span 
                                                        onClick={(e) => {
                                                            openJiraModal(e)
                                                            setJiraData(value && value.jiraTask)
                                                            setCampaignId(value && value._id)
                                                        }}
                                                        style={{cursor:"pointer"}}
                                                    >
                                                        <EditSvg />
                                                    </span>
                                                }
                                                placement="right"
                                            >
                                                <a
                                                    href={value.jiraTask.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ fontSize: "14px", color: "#000000" }}
                                                >
                                                    {value.jiraTask.key}
                                                </a>
                                            </Tooltip>
                                            :
                                            <BiPlusCircle
                                                style={{ fontSize: "22px", marginLeft: "10px", cursor: "pointer" }}
                                                onClick={(e) => {
                                                    openJiraModal(e)
                                                    setCampaignId(value && value._id)
                                                }}
                                            />
                                    }
                                </td>
                                <td>
                                    <div className=" input_form" style={{ position: "relative" }}>
                                        <select
                                            id="inputState"
                                            className="selectpicker"
                                            onChange={(event) => updateStatusCompaing(event, value)}
                                            style={{
                                                width: "95%",
                                                height: "33px",
                                                border: "0px solid rgba(0, 0, 0, 0.15)",
                                                borderColor: "#ced4da",
                                                borderRadius: "0.25rem",
                                                color: "black",
                                                backgroundColor: bgCheck(value.status),
                                                cursor: "pointer",
                                                fontSize: "14px",
                                                outline: "none",
                                            }}
                                        >
                                            {progressStatus.map((item, index) => (
                                                <option
                                                    key={index}
                                                    value={item.value}
                                                    selected={value.status === item.value}
                                                    style={{
                                                        marginBottom: "0",
                                                        fontWeight: "400",
                                                        fontSize: "14px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                                <td className="td_radiusRight" style={{ display: 'flex' }}>
                                    <div className="dropdown">
                                        <div className="dropdown-toggle" data-bs-toggle="dropdown" style={{ fontSize: '14px', marginTop: '5px', marginLeft: '5px' }}>
                                            <img
                                                src={iconEdit}
                                                alt="iconEdit"
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </div>
                                        <div className="dropdown-menu leftMenu" style={{ zIndex: 99 }}>
                                            <Link
                                                to={`/viewFormIntake?cam=${value._id}`}
                                                target="_blank"
                                                style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    fontSize: "14px",
                                                }}
                                                className="dropdown-item">
                                                View Form
                                            </Link>
                                            <Link
                                                to="#!"
                                                className="dropdown-item"
                                                style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    fontSize: "14px",
                                                }}
                                                onClick={() => deleteCampaginMessage(value)}
                                            >
                                                Delete Form
                                            </Link>
                                            <Link
                                                className="dropdown-item"
                                                style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    fontSize: "14px",
                                                }}
                                                onClick={() => downloadPDF(value)}
                                            >
                                                Download Form PDF
                                            </Link>
                                        </div>
                                    </div>
                                    {
                                        value.isPriority === 1 ? <FaStar onClick={() => updatePriority(value._id, value.isPriority, value)} style={{ width: '20px', color: "#ffc107", marginTop: '10px', marginLeft: '10px', cursor:"pointer" }}></FaStar> : <FaRegStar onClick={() => updatePriority(value._id, value.isPriority, value)} style={{ width: '20px', color: "#ffc107", marginTop: '10px', marginLeft: '10px', cursor:"pointer" }}></FaRegStar>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            {/* </Spin> */}
            </div>
            {totalCount >= 10 && (
                <CustomPagination
                    totalCount={totalCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    limitList={limitList}
                    setLimitList={setLimitList}
                />
            )}
            <MessageModal
                icon={iconWarning}
                title="Warning"
                description="Are you sure you want to delete this form?"
                showModal={messageModal}
                setMessageModal={setMessageModal}
            />
            <MessageModal
                icon={iconWarning}
                title="Warning"
                description="Are you sure you want to delete this form?"
                showModal={messageModal}
                setMessageModal={setMessageModal}
                deleteUser={deleteUser}
                indexDelete={indexDelete}
            />
            <MessageModalMain textCentered ref={messageModalRef} />
            <JireModal
                showModal={showModal}
                setShowModal={setShowModal}
                setStakeholderFetch={setJiraShow}
                campaignId={campaignId}
                jiraData={jiraData}
                setJiraData={setJiraData}
                fetchUserFormList={() => {
                    setIsLoading()
                    fetchUserFormList()
                }}
            />
        </div>

    );
};

export default WebFormList;
