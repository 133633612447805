import React, { useEffect, useState } from "react";
import "../../styles/dashboard.css";
import "../../styles/tables.css";
import WebFormList from "../../components/webFormList";
import LoadingOverlay from "../../containers/loading/loadingOverlay";
import SearchFilterHeader from "../../components/searchFilterHeader";
import API from "../../utils/request";
import { COMPAIGN_LIST_URL } from "../../api/urls";
import { formatDateAPI1 } from "../../functions/format/date";
import { dataRequestTypeValue, progressStatus } from "../../containers/FixedData/fixedData";
import WebFormDropList from "../../components/webFormDropList";

const Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingTop, setIsLoadingTop] = useState(false)
    const [pagination, setPagination] = useState(1);
    const [totalCount, setTotalCount] = useState();
    const [searchStatus, setSearchStatus] = useState("Name");
    const [search, setSearch] = useState("");
    const [submissionValue, setSubmissionValue] = useState("");
    const [requestType, setRequestType] = useState("");
    const [beforeDate, setBeforeDate] = useState();
    const [afterDate, setAfterDate] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [clear, setclear] = useState("")
    const [sortField, setSortField] = useState("_id");
    const [sort, setSort] = useState(-1);
    const [limitList, setLimitList] = useState(10);
    const [dataDontationHistory, setdataDontationHistory] =
        useState(progressStatus);

    useEffect(() => {
        fetchUserFormList();
        setclear('')
    }, [pagination, clear]);


    const arrStatus = [];
    dataDontationHistory.forEach((it) => {
        if (it.isCheck) {
            arrStatus.push(it.value);
        }
    });

    const fetchUserFormList = (isHide) => {
        !isHide && setLoading(true);
        var params = {
            searchParams: {
                name: `${searchStatus === "Name" ? search : ""}`,
                email: `${searchStatus === "Email" ? search : ""}`,
                department: "",
                title: `${searchStatus === "Title" ? search : ""}`,
                status: arrStatus.length > 0 ? arrStatus : "",
                submissionDate: `${submissionValue ? submissionValue.toLowerCase() : ""
                    }`,
                fromDate: `${fromDate
                    ? formatDateAPI1(fromDate)
                    : afterDate
                        ? formatDateAPI1(afterDate)
                        : ""
                    }`,
                toDate: `${toDate
                    ? formatDateAPI1(toDate)
                    : beforeDate
                        ? formatDateAPI1(beforeDate)
                        : ""
                    }`,
                requestType : requestType ? dataRequestTypeValue(requestType) :"",
                // isPriority: 0
 
            },
            limit: limitList,
            page: pagination,
            sorts: {
                [sortField]: sort
            }
        };
        if(sortField === "title") {
            params.sorts.summaryIssue = sort
        }
        API.post(COMPAIGN_LIST_URL, params)
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    setDataForm(res.data);
                    setTotalCount(res.totalCount);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };


    return (
        <div className="container p-0" style={{ marginTop: '120px' }}>
            {/* Global LoadingOverlay */}
            {loading ? <LoadingOverlay /> : null}
            <h3 className="dashboardH3">Intake Webform Management System Dashboard</h3>
            <h4 className="dashboardSubH4" style={{marginTop:"2.5rem"}}>Priority Requests</h4>
            <div className="dashboard">
                <WebFormDropList 
                    setLoading={setLoading}
                    loading={loading}
                    limitList={limitList}
                    dataForm={dataForm}
                    fetchUserFormListNew={fetchUserFormList}
                    pagination={pagination}
                    setPagination={setPagination}
                    sort={sort}
                    setSort={setSort}
                    setSortField={setSortField}
                    sortField={sortField}
                    totalCount={totalCount}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isLoadingTop={isLoadingTop}
                />
                    <h4 className="dashboardSubH4 mb-3">All Requests</h4>
                {/* Search & Filter Header */}
                <SearchFilterHeader
                    setSearchStatus={setSearchStatus}
                    searchStatus={searchStatus}
                    setSearch={setSearch}
                    search={search}
                    fetchUserFormList={fetchUserFormList}
                    setdataDontationHistory={setdataDontationHistory}
                    dataDontationHistory={dataDontationHistory}
                    arrStatus={arrStatus}
                    setBeforeDate={setBeforeDate}
                    setAfterDate={setAfterDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    beforeDate={beforeDate}
                    afterDate={afterDate}
                    fromDate={fromDate}
                    toDate={toDate}
                    setSubmissionValue={setSubmissionValue}
                    submissionValue={submissionValue}
                    requestType={requestType} 
                    setRequestType={setRequestType}
                    setPagination={setPagination}
                    setclear={setclear}
                    sort={sort}
                    sortField={sortField}
                />
                {/* Table */}
                <div className="col-12 mt-3">
                    <div className="row">
                        <WebFormList
                            setLoading={setLoading}
                            limitList={limitList}
                            setLimitList={setLimitList}
                            dataForm={dataForm}
                            setDataForm={setDataForm}
                            fetchUserFormList={fetchUserFormList}
                            pagination={pagination}
                            setPagination={setPagination}
                            sort={sort}
                            setSort={setSort}
                            setSortField={setSortField}
                            sortField={sortField}
                            totalCount={totalCount}
                            setIsLoading={() => {
                                setIsLoadingTop(false)
                                setTimeout(() => {
                                    setIsLoadingTop(true)
                                }, 100);
                            }}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
