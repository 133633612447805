import React, { useState, useEffect, useRef } from "react";
import API from "../utils/request";
import { COMPAIGN_DELETE_URL, DOWNLOAD_PDF, DragDrop_URL, LIST_PRIORITY, UPDATE_PRIORITY, UPDATE_STATUS } from "../api/urls";
import { Link } from "react-router-dom";
import iconEdit from "../assets/images/iconEdit.svg";
import { FeedESTDateToString, formatDateAPI1 } from "../functions/format/date";
import { BiPlusCircle, BiSortDown, BiSortUp } from 'react-icons/bi'
import { bgCheck, dataRequestTypeList, dataRequestTypeValue, progressStatus } from '../containers/FixedData/fixedData';
import iconWarning from "../assets/images/warning-icon.jpg"
import MessageModal from '../containers/modal/messageModal';
import MessageModalMain from "../containers/modal/messageModalMain";
import downloadjs from 'js-file-download';
import JireModal from "../containers/modal/jiraModal";
import { Spin, Tooltip } from "antd";
import EditSvg from "../assets/images/editSvg";
import { MdDragIndicator } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const WebFormDropList = (props) => {
    const {
        setLoading,
        fetchUserFormListNew,
        isLoading,
        setIsLoading,
        isLoadingTop
    } = props;
    const messageModalRef = useRef('rememberMe');
    const [messageModal, setMessageModal] = useState(false)
    const [indexDelete, setIndexDelete] = useState(null)
    const [messageFetch, setMessageFetch] = useState("")
    const [item, setItem] = useState("")
    const [id, setId] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [jiraShow, setJiraShow] = useState("")
    const [campaignId, setCampaignId] = useState("")
    const [jiraData, setJiraData] = useState("")
    const [dataForm, setDataForm] = useState([]);
    const [pagination, setPagination] = useState(1);
    const [totalCount, setTotalCount] = useState();
    const [searchStatus, setSearchStatus] = useState("Name");
    const [search, setSearch] = useState("");
    const [submissionValue, setSubmissionValue] = useState("");
    const [requestType, setRequestType] = useState("");
    const [beforeDate, setBeforeDate] = useState();
    const [afterDate, setAfterDate] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [clear, setclear] = useState("")
    const [sortField, setSortField] = useState("order");
    const [sort, setSort] = useState(1);
    const [dataDontationHistory, setdataDontationHistory] = useState(progressStatus);

    const [sortFields, setSortFields] = useState([
        {
            name: "Date",
            sort: 1,
            sortField: "_id",

        },
        {
            name: "Name",
            sort: 1,
            sortField: "firstName",

        },
        {
            name: "Email",
            sort: 1,
            sortField: "email",

        },
        {
            name: "Title",
            sort: 1,
            sortField: "title",

        },
        {
            name: "Request Type",
            sort: 1,
            sortField: "requestType",

        },
        {
            name: "JIRA#",
            sort: 1,
            sortField: "jira"

        },
        {
            name: "Progress",
            sort: 1,
            sortField: "status"

        },
    ]);

    const updateStatusCompaing = (event, item) => {
        setIsLoading(false)
        API.post(UPDATE_STATUS, {
            campaignId: item._id,
            status: event.target.value,
        })
            .then((res) => {
                if (res.status === 1) {
                    item.status = event.target.value
                    setDataForm([...dataForm])
                    fetchUserFormList(true);
                    fetchUserFormListNew(true);
                    setIsLoading(false)
                } else {
                    alert(res.message);
                }
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    // Sort by ascending & descending for stakeholder
    const handleSort = (item, index) => {
        setSort(item.sort)
        if (item.sort === 1) {
            sortFields[index].sort = -1;
        } else {
            sortFields[index].sort = 1
        }
        setSortField(item.sortField);
        setSortFields([...sortFields])
    }

    // change icon when sort ascending && descending
    const changeIcon = (type) => {
        switch (type) {
            case 1:
                return <BiSortDown />;
            case -1:
                return <BiSortUp />;
            default:
                return null;
        }
    };
    // update-priority
    const updatePriority = (id, isPriority, item) => {
        API.post(UPDATE_PRIORITY, {
            campaignId: id,
            isPriority: isPriority === 1 ? 0 : 1
        }).then((res) => {
            if (res.status === 1) {
                item.isPriority = isPriority === 1 ? 0 : 1
                setDataForm([...dataForm])
                setLoading(false)
                setIsLoading(false)
                fetchUserFormList();
                fetchUserFormListNew(true);
            }
            return false;
        }).catch((err) => {
            setLoading(false)
            setIsLoading(false)
            console.log(err)
        })
    }

    const deleteUser = (e) => {
        API.post(COMPAIGN_DELETE_URL, {
            "campaignId": id
        }).then((res) => {
            if (res.status === 1) {
                messageModalRef.current.showSuccess("The intake webform has been successfully deleted.");
                setMessageModal(false)
                fetchUserFormList()
                return false;
            } else {
                messageModalRef.current.showWarning(res.message);
            }
        }).catch((err) => {
            messageModalRef.current.showWarning(err.message);
            setIsLoading(false)
        })
    }
    const deleteCampaginMessage = (item, index) => {
        setIndexDelete(index)
        setMessageFetch("Open")
        setItem(item)
        setMessageModal(true)
        setId(item._id)

    };

    const downloadPDF = (item) => {
        API.post(DOWNLOAD_PDF, {
            "campaignId": item._id,
        }, { responseType: 'blob' }).then((res) => {
            downloadjs(res, `CampaignIntakeForm_${item.firstName + ' ' + item.lastName}_${item.title}.pdf`);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false)
        })
    }

    function split(item) {
        var avg, splitted, part1, part2;
        splitted = item.split("@");
        part1 = splitted[0];
        avg = part1.length / 2;
        part1 = part1.substring(0, (part1.length - avg));
        part2 = splitted[1];
        return part1 + "...@" + part2;
    }

    useEffect((isHide) => {
        !isHide && setLoading(true);
        fetchUserFormList()
    }, [sortField, sort]);

    const openJiraModal = (e) => {
        e.preventDefault()
        setJiraShow("Open")
        setShowModal(true)
    }

    useEffect(() => {
        fetchUserFormList();
        setclear('')
    }, [pagination, clear]);


    const arrStatus = [];
    dataDontationHistory.forEach((it) => {
        if (it.isCheck) {
            arrStatus.push(it.value);
        }
    });

    const fetchUserFormList = (isHide) => {
        !isHide && setIsLoading(true);
        var params = {
            searchParams: {
                name: `${searchStatus === "Name" ? search : ""}`,
                email: `${searchStatus === "Email" ? search : ""}`,
                department: "",
                title: `${searchStatus === "Campaign Title" ? search : ""}`,
                status: arrStatus.length > 0 ? arrStatus : "",
                submissionDate: `${submissionValue ? submissionValue.toLowerCase() : ""}`,
                fromDate: `${fromDate ? formatDateAPI1(fromDate) : afterDate ? formatDateAPI1(afterDate) : ""}`,
                toDate: `${toDate ? formatDateAPI1(toDate) : beforeDate ? formatDateAPI1(beforeDate) : ""}`,
                requestType: requestType ? dataRequestTypeValue(requestType) : "",
            },
            sorts: {
                [sortField]: sort
            }
        };
        if (sortField === "title") {
            params.sorts.summaryTitle = sort
        }
        API.post(LIST_PRIORITY, params)
            .then((res) => {
                setIsLoading(false);
                setLoading(false)
                if (res.status === 200) {
                    const sortedData = res.data;
                    setDataForm(sortedData);
                    setTotalCount(res.totalCount);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    useEffect(() => {
        fetchUserFormList()
    }, []);

    useEffect(() => {
        if (isLoadingTop) {
            fetchUserFormList(true)
        }
    }, [isLoadingTop]);

    const dragDropList = (dataForm) => {
        setIsLoading(true);

        const params = {
            data: dataForm.map((item, index) => ({
                _id: item._id,
                order: index + 1,
            })),
        };

        API.post(DragDrop_URL, params)
            .then((res) => {
                setIsLoading(false);
                if (res.status === 200) {
                    setDataForm([...dataForm]);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) return;

        const reorderedItems = [...dataForm];
        const [removed] = reorderedItems.splice(source.index, 1);
        reorderedItems.splice(destination.index, 0, removed);

        setDataForm(reorderedItems);
        dragDropList(reorderedItems);
    };

    return (
        <div className="col-md-12" style={{ marginBottom: "3rem" }}>
            <div className="table-responsive" style={{ maxHeight: '400px', overflow: "auto" }}>
                {/* <Spin spinning={isLoading}> */}
                    <table className="table">
                        <thead className="thead-primary">
                            <tr>
                                <th>No.</th>
                                {
                                    sortFields.map((field, i) => {
                                        if (field.name === "JIRA#") {
                                            return (
                                                <th key={i}>
                                                    {field.name}
                                                </th>
                                            );
                                        }
                                        return (
                                            <th key={i} onClick={() => handleSort(field, i)}>
                                                {field.name} {changeIcon(field.sort)}
                                            </th>
                                        );
                                    })
                                }
                                <th>Action</th>
                            </tr>
                        </thead>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="tableRows">
                                {(provided) => (
                                    <tbody
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {dataForm.map((value, index) => (
                                            <Draggable
                                                key={value._id}
                                                draggableId={value._id}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                     value.isPriority === 1 ?<tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                            borderBottom: "solid rgba(232,234,239,1)",
                                                            borderBottomWidth: "0.0625rem",
                                                            display: "table-row",
                                                            borderRadius:"10px",
                                                            
                                                        }}
                                                    >
                                                        <td className="td_radiusLeft cusDrag">
                                                            <span {...provided.dragHandleProps}>
                                                                <MdDragIndicator className="fs-4 me-1" style={{ marginLeft: "-5px" }} />
                                                            </span>
                                                            {index + 1}
                                                        </td>
                                                        <td className="cusDrag1">{FeedESTDateToString(value.submitDate)}</td>
                                                        <td className="cusDrag2">{`${value.firstName} ${value.lastName}`}</td>
                                                        <td className="cusDrag3">
                                                            <Tooltip placement="top" title={value.email}>
                                                                {split(value.email)}
                                                            </Tooltip>
                                                        </td>
                                                        <td className="cusDrag4">
                                                            <Tooltip
                                                                placement="top"
                                                                title={
                                                                    value.requestType === "technical-ticket"
                                                                        ? value.summaryTitle
                                                                        : value.title
                                                                }
                                                            >
                                                                {value.requestType === "technical-ticket"
                                                                    ? value.summaryTitle && value.summaryTitle.length > 30
                                                                        ? value.summaryTitle.slice(0, 30) + "..."
                                                                        : value.summaryTitle
                                                                    : value.title && value.title.length > 30
                                                                        ? value.title.slice(0, 30) + "..."
                                                                        : value.title}
                                                            </Tooltip>
                                                        </td>
                                                        <td className="cusDrag5">{`${dataRequestTypeList(value?.requestType)}`}</td>
                                                        <td className="cusDrag6">
                                                            {value.jiraTask && value.jiraTask.key ? (
                                                                <Tooltip
                                                                    title={
                                                                        <span
                                                                            onClick={(e) => {
                                                                                openJiraModal(e);
                                                                                setJiraData(value.jiraTask);
                                                                                setCampaignId(value._id);
                                                                            }}
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            <EditSvg />
                                                                        </span>
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <a
                                                                        href={value.jiraTask.link}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{
                                                                            fontSize: "14px",
                                                                            color: "#000000",
                                                                        }}
                                                                    >
                                                                        {value.jiraTask.key}
                                                                    </a>
                                                                </Tooltip>
                                                            ) : (
                                                                <BiPlusCircle
                                                                    style={{
                                                                        fontSize: "22px",
                                                                        marginLeft: "10px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={(e) => {
                                                                        openJiraModal(e);
                                                                        setCampaignId(value._id);
                                                                    }}
                                                                />
                                                            )}
                                                        </td>
                                                        <td className="cusDrag7">
                                                            <div className="input_form" style={{ position: "relative" }}>
                                                                <select
                                                                    id="inputState"
                                                                    className="selectpicker"
                                                                    onChange={(event) =>
                                                                        updateStatusCompaing(event, value)
                                                                    }
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "33px",
                                                                        border: "0px solid rgba(0, 0, 0, 0.15)",
                                                                        borderColor: "#ced4da",
                                                                        borderRadius: "0.25rem",
                                                                        color: "black",
                                                                        backgroundColor: bgCheck(value.status),
                                                                        cursor: "pointer",
                                                                        fontSize: "14px",
                                                                        outline: "none",
                                                                    }}
                                                                >
                                                                    {progressStatus.map((item, index) => (
                                                                        <option
                                                                            key={index}
                                                                            value={item.value}
                                                                            selected={value.status === item.value}
                                                                            style={{
                                                                                marginBottom: "0",
                                                                                fontWeight: "400",
                                                                                fontSize: "14px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            {item.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td className="td_radiusRight cusDrag8" style={{marginRight:snapshot.left? "220px" :""}}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <div className="dropdown">
                                                                    <div
                                                                        className="dropdown-toggle"
                                                                        data-bs-toggle="dropdown"
                                                                        style={{ fontSize: "14px", margin: "5px" }}
                                                                    >
                                                                        <img
                                                                            src={iconEdit}
                                                                            alt="iconEdit"
                                                                            style={{
                                                                                width: "18px",
                                                                                height: "18px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="dropdown-menu leftMenuNew"
                                                                        style={{ zIndex: 99 }}
                                                                    >
                                                                        <Link
                                                                            to={`/viewFormIntake?cam=${value._id}`}
                                                                            target="_blank"
                                                                            style={{
                                                                                color: "black",
                                                                                textDecoration: "none",
                                                                                fontSize: "14px",
                                                                            }}
                                                                            className="dropdown-item"
                                                                        >
                                                                            View Form
                                                                        </Link>
                                                                        <Link
                                                                            to="#!"
                                                                            className="dropdown-item"
                                                                            style={{
                                                                                color: "black",
                                                                                textDecoration: "none",
                                                                                fontSize: "14px",
                                                                            }}
                                                                            onClick={() => deleteCampaginMessage(value)}
                                                                        >
                                                                            Delete Form
                                                                        </Link>
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            style={{
                                                                                color: "black",
                                                                                textDecoration: "none",
                                                                                fontSize: "14px",
                                                                            }}
                                                                            onClick={() => downloadPDF(value)}
                                                                        >
                                                                            Download Form PDF
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                {value.isPriority === 1 && (
                                                                    <FaStar
                                                                        onClick={() =>
                                                                            updatePriority(value._id, value.isPriority, value)
                                                                        }
                                                                        style={{
                                                                            width: "20px",
                                                                            color: "#ffc107",
                                                                            margin: "10px",
                                                                            cursor: "pointer",
                                                                            
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </td>

                                                    </tr> :""
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </table>
                {/* </Spin> */}
            </div>
            <MessageModal
                icon={iconWarning}
                title="Warning"
                description="Are you sure you want to delete this form?"
                showModal={messageModal}
                setMessageModal={setMessageModal}
            />
            <MessageModal
                icon={iconWarning}
                title="Warning"
                description="Are you sure you want to delete this form?"
                showModal={messageModal}
                setMessageModal={setMessageModal}
                deleteUser={deleteUser}
                indexDelete={indexDelete}
            />
            <MessageModalMain textCentered ref={messageModalRef} />
            <JireModal
                showModal={showModal}
                setShowModal={setShowModal}
                setStakeholderFetch={setJiraShow}
                campaignId={campaignId}
                jiraData={jiraData}
                setJiraData={setJiraData}
                fetchUserFormList={fetchUserFormList}
                fetchUserFormListNew={fetchUserFormListNew}
            />
        </div>
    );
};

export default WebFormDropList;
