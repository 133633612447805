import { useEffect } from "react";
import { storageKeys } from "../constants/storage";

const AuthenticationComponent = (props) => {
    const tokenss = localStorage.getItem(storageKeys.TOKEN);
    useEffect(() => {
        setInterval(() => {
            if (tokenss) {
                const tokens = localStorage.getItem(storageKeys.TOKEN);
                if(!tokens) {
                    localStorage.removeItem(storageKeys.TOKEN)
                    localStorage.removeItem(storageKeys.USER)
                    window.location = "/login"
                }
                
                const jwtPayload = JSON.parse(atob(tokens.split('.')[1]))
                const userID = jwtPayload._id
                const JWTtime = jwtPayload.exp * 1000
                const NewTokenAccess = JWTtime - 10 * 60 * 1000
                
                if (Date.now() > NewTokenAccess) {
                    localStorage.removeItem(storageKeys.TOKEN)
                    localStorage.removeItem(storageKeys.USER)
                    window.location = "/login"
                }
            }
        }, 3000)
    }, [tokenss])
    
    return props.children
}
export default AuthenticationComponent;