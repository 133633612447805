import React from 'react';
import moment from 'moment';
import { IoMdArrowForward } from 'react-icons/io';
import { formatDate2 } from '../functions/format/date';
import { numberFormat } from '../containers/FixedData/fixedData';


// Helper function to format the key with capitalization and spaces
const formatKey = (key) => {
    switch (key) {
        case `haveApproved`:
                return `Has the campaign offer/budget been approved?`
            break;

        default:
            return key
                .replace(/([A-Z])/g, ' $1')  // Add space before uppercase letters
                .replace(/^./, (str) => str.toUpperCase());  // Capitalize the first letter
            break;
    }
};

const FieldHistory = (props) => {
    const { compaignData } = props;
    const historyData = compaignData?.historyData || [];

    const formatDate = (dateStr) => {
        if (!dateStr) return "N/A";
        const date = moment(dateStr);
        if (!date.isValid()) return dateStr;
        return date.format("MM-DD-YYYY");
    };

    const formatDateNew = (dateStr) => {
        if (!dateStr) return "N/A";
        const date = moment(dateStr);
        if (!date.isValid()) return dateStr;
        return date.format("MM-DD-YYYY h:mm A");
    };
    const audienceKey = (type) => {
        switch (type) {
            case "between":
                return "Between";
            case "less-then":
                return "Less Than";
            case "greater-then":
                return "Greater Than";
            default:
                return type
        }
    };

    const donationDateKey = (type) => {
        switch (type) {
            case "before":
                return "Before";
            case "after":
                return "After";
            case "between":
                return "Between";
            default:
                return type
        }
    };

    const formatValue = (value, key) => {
        if (typeof value === 'object' && value !== null) {
            if (key === 'targetAudience') {
                return value
                    .map((item, index) => {
                        const inputType = item.inputType || "Unknown";
                        if (inputType === 'donor-type') {
                            return (
                                <div key={index}>
                                    <span style={{ fontSize: "14px", fontWeight: 500 }}>Donor Types :</span> {item.donorType?.join(", ") || "None"}
                                </div>
                            );
                        }
                        if (inputType === 'donation-history') {
                            const donationAmount = item.donationAmount?.enable === 'yes'
                                ? `${audienceKey(item.donationAmount.key) || ""} ${item.donationAmount.value?.join(" ") || ""}`
                                : "";
                            const donationDate = item.donationDate?.enable === 'yes'
                                ? `${donationDateKey(item.donationDate.key) || ""} ${item.donationDate.value?.join(" ") || ""}`
                                : "";
                            const noDonation = item.noDonation ? `No Donation` : "";
                            return (
                                <div key={index}>
                                    <>
                                        <span style={{ fontSize: "14px", fontWeight: 500 }}>Donation History :</span> {noDonation}
                                        {
                                            item.donationAmount?.enable === 'yes' ?
                                                <div>
                                                    <span style={{ fontSize: "14px", fontWeight: 500 }}>Donation Amount : </span>{donationAmount}
                                                </div> : ""
                                        }
                                        {
                                            item.donationDate?.enable === 'yes' ?
                                                <div>
                                                    <span style={{ fontSize: "14px", fontWeight: 500 }}>Donation Date : </span>{formatDate2(donationDate)}
                                                </div> : ""
                                        }
                                        {
                                            item?.other === 'yes' ?
                                                <div>
                                                    <span style={{ fontSize: "14px", fontWeight: 500 }}>Other : </span>{item?.other === 'yes' ? item.additionalText : ""}
                                                </div> : ""
                                        }
                                    </>
                                </div>
                            );
                        }
                        if (inputType === 'location') {
                            const locationText = item.allCenter === 'yes' ? <p style={{ fontSize: '16px' }}>{`${!item.typeCenter && !item.typePrCenter ? "All US Centers" : `${item.typeCenter === 'all_us_centers' ? 'All US Centers' : ""}${item.typeCenter === 'all_us_centers' && item.typePrCenter === 'all_pr_centers' ? ", " : ""} ${item.typePrCenter === 'all_pr_centers' ? 'All PR Centers' : ""} `}`}</p>
                            :
                            <p className="" style={{ fontSize: '16px' }}>{
                                item.centers ? item.centers.map((lo) => {
                                    return `${(lo)}, `
                                }) : ''
                            }</p>;
                            const typeCenter = item.typeCenter && item.typeCenter !== 'all_us_centers' ? `(${item.typeCenter})` : "";
                            return (
                                <>
                                    <div key={index}>
                                        <span style={{ fontSize: "14px", fontWeight: 500 }}>Location :</span> {locationText} {typeCenter}
                                    </div>
                                    {
                                        item.other === "yes" ?
                                        <><span style={{ fontSize: "14px", fontWeight: 500 }}>Other :</span> {item && item.additionalText}</> :""
                                    }
                                </>
                            );
                        }
                        return (
                            <div key={index}>
                                <strong>Unknown Type :</strong> {JSON.stringify(item, null, 2)}
                            </div>
                        );
                    });
            }


            if (key === 'personalization') {
                return value
                    .map((item) => {
                        const dynamicContents = item.dynamicContents
                            ?.map((content) => content.type)
                            .join(", ") || "None";

                        const additionalText = item.additionalText || "No Additional Text";

                        // Combine dynamic content and additional text
                        if (additionalText.trim()) {
                            return `${dynamicContents} : ${additionalText}`;
                        }
                        return dynamicContents;
                    })
                    .join("\n");
            }

            if (key === 'contentDesignAsset' || key === 'audienceExceptionAsset' || key === 'designAssetLeverage' || key === 'additionalDocumentation') {
                return value
                    .map((item) => {
                        const urls = item.files?.map((file) => file.url).join(", ") || item.url || "No File";
                        const language = item.keyLanguage ? `Language: ${item.keyLanguage}` : "";
                        return `${language} ${urls}`.trim();
                    })
                    .join(" | ");
            }

            // if (Array.isArray(value) && key === 'addKeyMessage') {
            //     return value
            //         .map((item) => {
            //             const lang = item.keyLanguage || "English";
            //             const keyMessage = item.keyMessage || "";
            //             const channels = item.channel
            //                 ?.filter((ch) => ch.isCheck === 'yes')
            //                 .map((ch) => ch.type.charAt(0).toUpperCase() + ch.type.slice(1))
            //                 .join(", ") || "No Channels";
            //             return `${"Campaign Goal / Key Message"}: ${keyMessage} Channels: ${channels}`;
            //         })
            //         .join(" | ");
            // }
            if (Array.isArray(value) && key === 'addKeyMessage') {
                return value
                    .map((item, index) => {
                        const lang = item.keyLanguage || "English";
                        const keyMessage = item.keyMessage || "";
                        const channels = item.channel
                            ?.filter((ch) => ch.isCheck === 'yes')
                            .map((ch) => ch.type === "app" ? "CSL Plasma App" : ch.type.charAt(0).toUpperCase() + ch.type.slice(1))
                            .join(", ") || "";
                        return (
                            <div key={index}>
                                <span style={{ fontSize: "14px", fontWeight: 500 }}>Campaign Goal / Key Message :</span> {keyMessage} <br />
                                <span style={{ fontSize: "14px", fontWeight: 500 }}>Channels :</span> {channels}
                            </div>
                        );
                    });
            }
            if (key === 'campaignIdea' || key === 'campaignRunmore' || key ==='campaignOffer' || key ==='legalDeclaimer' || key==="languageApply") {
                return value
                .map((item) => item.type)
                .join(", ");
            }

            return JSON.stringify(value, null, 2);
        }
        return value;
    };

    const historyDataValue = historyData.map((item, index) => {
        const changes = item?.changes || {};
    
        return Object.keys(changes).map((key) => {
            let oldValue, newValue;
    
            if (key === "adhocEvergreen") {
                // Specific handling for adhocEvergreen
                oldValue = changes[key]?.oldValue ? changes[key]?.oldValue.title : "";
                newValue = changes[key]?.newValue ? changes[key]?.newValue.title : "";
            } else if(key === "haveApproved") {
                const oldTitle = changes[key]?.oldValue?.title || "";
                const newTitle = changes[key]?.newValue?.title || "";
                const oldInsertName = changes[key]?.oldValue?.insertName || "";
                const formattedSubmitDate = formatDateNew(item?.submitDate);
                const newInsertName = changes[key]?.newValue?.insertName || "";
            return (
                <div key={`${index}-${key}`} style={{ fontSize: "14px", color: "black" }}>
                    <hr />
                    <div style={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px" }}>
                                Date: <span style={{ fontSize: "14px", fontWeight: 400, color: "black" }}>{formattedSubmitDate}</span>
                            </div>
                    <div style={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px" }}>
                        <span style={{ fontSize: "14px", fontWeight: 500 }}>{formatKey(key)}</span> :
                    </div>
                    <div>
                        <div>
                            <span style={{  fontWeight: 500 }}>{oldTitle === "Enter name of approver*" ? "Approved by" : oldTitle}</span>{" "}
                            <span >{oldInsertName}</span>{" "}
                            {oldInsertName !== newInsertName && (
                                <>
                                    <strong style={{ margin: "0 5px" }}><IoMdArrowForward /></strong>
                                    <span>{newInsertName}</span>
                                </>
                            )}
                            {oldTitle !== newTitle && (
                                <>
                                    <strong style={{ margin: "0 5px" }}><IoMdArrowForward /></strong>
                                    <span>{newTitle}</span>
                                </>
                            )}
                        </div>
                        {/* <div>
                           <span style={{ marginLeft: "10px" }}>{oldInsertName}34</span>{" "}
                            {oldInsertName !== newInsertName && (
                                <>
                                    <strong style={{ margin: "0 5px" }}><IoMdArrowForward /></strong>
                                    <span>{newInsertName}3</span>
                                </>
                            )}
                        </div> */}
                    </div>
                </div>
            )} 
            else {
                // General case for other keys
                oldValue = changes[key]?.oldValue ? formatValue(changes[key]?.oldValue, key) : null;
                newValue = changes[key]?.newValue ? formatValue(changes[key]?.newValue, key) : null;
    
                // Format dates if applicable
                oldValue = typeof oldValue === "string" && /^\d{4}-\d{2}-\d{2}T/.test(oldValue) ? formatDate(oldValue.slice(0,10)) : oldValue;
                newValue = typeof newValue === "string" && /^\d{4}-\d{2}-\d{2}T/.test(newValue) ? formatDate(newValue.slice(0,10)) : newValue;
            }
    
            if (oldValue === null && newValue === null) return null;
            const formattedKey = formatKey(key);
            const formattedSubmitDate = formatDateNew(item?.submitDate);
            const historyKey = (type) => {
                switch (type) {
                    case "Date Discovered":
                        return "Date of issue discovered";
                    case "Date Resolution":
                        return "Due date for desired resolution";
                    case "Re Submit Date":
                        return "Resubmitted on";
                    case "Target Audience":
                        return "Initial Target Audience";
                    case "Audience Exception":
                        return "Audience List";
                    case "Total Approximate":
                        return "List count of control group";
                    case "Language Apply":
                        return "Language";
                    case "People Approve":
                        return "Please list the people who have approved this campaign";
                    case "Content Apply":
                        return "Where will the content be applied?";
                    case "Summary Issue":
                        return "Summary of issue";
                    case "Add Key Message":
                        return "Campaign Details";
                    case "Additional Documentation":
                        return "Any additional documentation to share for this request?";
                    case "Audience Exception Asset":
                        return "Audience List Asset";
                    case "Name Approver":
                        return "Approved by";
                    case "Campaign Offer": 
                        return "Has the campaign offer/budget been approved?";
                    case "Campaign Have Offer": 
                        return "Does the campaign have an offer?";
                    case "Have Approved": 
                        return "Has your disclaimer been approved by legal?";
                    case "Enter name of approver*":
                        return "Approved by";
                    case "Control Group":
                        return "Does this campaign have a control group?";
                    case "Summary Title":
                        return "Issue Title";
                    case "Content Request":
                        return "This request is for";
                    default:
                        return type
                }
            };
    
            switch (key) {
                case "campaignHaveOffer":
                    oldValue = JSON.parse(oldValue)
                    newValue = JSON.parse(newValue)
                    return (
                        <div key={`${index}-${key}`} style={{ fontSize: "14px", color: "black" }}>
                            {formattedSubmitDate && (
                                <>
                                    <hr />
                                    <div style={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px" }}>
                                        Date : <span style={{ fontSize: "14px", fontWeight: 400, color: "black" }}>{formattedSubmitDate}</span>
                                    </div>
                                </>
                            )}
                            {/* <span style={{ fontSize: "14px", fontWeight: 500 }}>{formattedKey}</span> : */}
                            <span style={{ fontSize: "14px", fontWeight: 500 }}>{historyKey(formattedKey)}</span> :
                            {oldValue !== null && (
                                <>
                                {
                                    formattedKey === "Total Approximate" ?
                                    <span style={{ fontSize: "14px" }}>{numberFormat(oldValue)}</span> : <span style={{ fontSize: "14px" }}> {oldValue.type}</span>
                                }
                                </>
                            )}
                            {oldValue !== null && newValue !== null && (
                                <strong style={{ fontSize: "14px" }}> <IoMdArrowForward /> </strong>
                            )}
                            {newValue !== null && (
                                <>
                                {
                                    formattedKey === "Total Approximate" ?
                                    <span style={{ fontSize: "14px" }}> {numberFormat(newValue)}</span> : <span style={{ fontSize: "14px" }}> {newValue.type}</span>
                                }
                                </>
                            )}
                        </div>
                    );
                    break;
            
                default:
                    return (
                        <div key={`${index}-${key}`} style={{ fontSize: "14px", color: "black" }}>
                            {formattedSubmitDate && (
                                <>
                                    <hr />
                                    <div style={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px" }}>
                                        Date: <span style={{ fontSize: "14px", fontWeight: 400, color: "black" }}>{formattedSubmitDate}</span>
                                    </div>
                                </>
                            )}
                            {/* <span style={{ fontSize: "14px", fontWeight: 500 }}>{formattedKey}</span> : */}
                            <span style={{ fontSize: "14px", fontWeight: 500 }}>{historyKey(formattedKey)}</span> :
                            {oldValue !== null && (
                                <>
                                {
                                    formattedKey === "Total Approximate" ? 
                                    <span style={{ fontSize: "14px" }}> {numberFormat(oldValue)}</span> : <span style={{ fontSize: "14px" }}> {oldValue}</span>
                                }
                                </>
                            )}
                            {oldValue !== null && newValue !== null && (
                                <strong style={{ fontSize: "14px" }}> <IoMdArrowForward /> </strong>
                            )}
                            {newValue !== null && (
                                <>
                                {
                                    formattedKey === "Total Approximate" ? 
                                    <span style={{ fontSize: "14px" }}> {numberFormat(newValue)}</span> : <span style={{ fontSize: "14px" }}> {newValue}</span>
                                }
                                </>
                            )}
                        </div>
                    );
                    break;
            }
        });
    });
    
    return (
        <div className="comment_form">
            <div className="d-flex flex-row align-items-center justify-content-between" style={{ marginBottom: '4px' }}>
                <label className="size18700">Change history</label>
            </div>
            <div className="form-control" style={{ minHeight: "200px", maxHeight: "200px", overflowY: "auto", overflowX: "hidden", backgroundColor: "#e9ecef" }}>
                {historyDataValue.flat()}
            </div>
        </div>
    );
};

export default FieldHistory;
