import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap'
import './index.css';
import './i18n/config'
import App from './App';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import IndexPage from './pages/indexPage';
import ChangePassword from './pages/changePassword';
import ManagementUser from './pages/managementUser/manageUsers';
import LoginPage from './pages/authenPage/loginPage';
import { storageKeys } from "./constants/storage";
import * as Storage from './utils/storage';
import ChangePasswordId from './pages/changePasswordId';
import PageNotFound from './pages/pageNotFound';
import ViewForm from './pages/dashboard/viewFrom';
import AuthenticationComponent from './layouts/AuthenticationComponent';

var token = Storage.getString(storageKeys.TOKEN);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route element={!token ? <Navigate to="/login" /> : <AuthenticationComponent> <App /> </AuthenticationComponent>}>
        <Route path='/' element={<IndexPage />} />
        <Route path='/viewFormIntake' element={<ViewForm />} />
        <Route path='/change/password' element={<ChangePassword />} />
        <Route path='/manage' element={<ManagementUser />} />
      </Route>
      <Route element={<App />}>
        <Route path='/login' element={<LoginPage />} />
      </Route>

      <Route element={<App />}>
        <Route path='/set-new-password' element={<ChangePasswordId />} />
        <Route
          path="*"
          element={
            <PageNotFound />
          }
        />
      </Route>
    </Routes>
  </Router>
);
